import React, { useEffect } from "react";
import "./navbar.css";
import logo from "../images/a3maal (1).png";
import logo2 from "../images/a3maal (2).png";
import { Container, Nav, Navbar } from "react-bootstrap";
const Navbarr = () => {
  const activeLink = () => {
    const link = document.querySelectorAll(".nn a");
    link.forEach((act) => {
      act.addEventListener("click", function () {
        link.forEach((btn) => btn.classList.remove("active"));
        this.classList.add("active");
      });
    });
  };
  useEffect(() => {
    activeLink();
  }, []);
  return (
    <>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand>
            <a href="https://a3maalapps.com/">
              <img style={{ width: "75px" }} src={logo} alt="" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto nn">
              {/* <NavDropdown title="الإدارة" id="basic-nav-dropdown">
          <NavDropdown.Item href="#action/3.3">تصدير</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">استيراد</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown title="إدارة القوائم" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.3">
              قائمة الملفات{" "}
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.4">
              قائمة المستخدم
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.4">
              قائمة الحساب الرئيسي
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.4">
              قائمة الحساب الفرعي
            </NavDropdown.Item>
          </NavDropdown>
        </NavDropdown>
        <NavDropdown title="اللغة" id="basic-nav-dropdown">
          <NavDropdown.Item href="#action/3.3">العربية</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">الإنجليزية</NavDropdown.Item>
        </NavDropdown> */}
              <Nav.Link className="active" href="#intro">
                {" "}
                الرئيسية
              </Nav.Link>
              <Nav.Link href="#app2">التطبيقات </Nav.Link>
              <Nav.Link href="#about">من نحن</Nav.Link>
              <Nav.Link href="#contact"> اتصل بنا</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand>
            <a href="https://a3maalapps.com/">
              <img style={{ width: "75px" }} src={logo2} alt="" />
            </a>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
};

export default Navbarr;
