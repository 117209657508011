import React from "react";
import "./someinfo.css";
import {Fade} from 'react-awesome-reveal'
const Someinfo = () => {
  return (
    <>
      {" "}
      <section id="divider">
        <div className="container">
          {/* <h2 className="title">Our Pricing</h2> */}
     
          <div className="row">
            <div
              className="col-md-4 wow fadeInUp templatemo-box"
              data-wow-delay="0.3s"
            >
                   <Fade direction="up"triggerOnce="false">
              <i className="fa fa-solid fa-shield-halved"></i>
              <h3 className="text-uppercase">الحماية</h3>
              <p>
                توفير تحديثات تلقائية للبرمجيات وإتاحة البرمجيات لعملائنا عبر
                الإنترنت على أساس نظام الدفع الشهري أو السنوي. حيث نقوم بإدارة
                البرمجيات التقليدية، بما في ذلك البرمجيات الوسيطة والبرمجيات
                التطبيقية والأمان. لذلك نمكن لعملاء تطبيقاتنا خدمة خفض التكاليف
              </p>
              </Fade>
            </div>
            <div
              className="col-md-4 wow fadeInUp templatemo-box"
              data-wow-delay="0.3s"
            >
                     <Fade direction="up"triggerOnce="false">
              <i className="fa fa-solid fa-feather-pointed"></i>
              <h3 className="text-uppercase">المهمة والرؤية</h3>
              <p>
                موقع أعمال سوفت وير منصة لعرض وتشغيل خدمة إستخدام برامج أعمال
                المعتمدة على تقنية SaaS مما يتيح تجربة مميزة للعملاء في استخدام
                البرامج لإنجاز مهامهم اليومية سواء للأفراد او أعمال المكاتب أو
                الشركات متناهية الصغر والصغيرة
              </p>
              </Fade>
            </div>
            <div
              className="col-md-4 wow fadeInUp templatemo-box"
              data-wow-delay="0.3s"
            >  <Fade direction="up"triggerOnce="false">
              <i className="fa fa-solid fa-lock"></i>{" "}
              <h3 className="text-uppercase">سياسة الخصوصية</h3>
              <p>
                تعنى (سياسة الخصوصية) لدينا بطريقة جمعنا وحفظنا واستخدامنا
                وطريقة حماية معلوماتك الشخصية ومن المهم أن تقوم بالإطلاع على
                سياسة الخصوصية هذه. ونقصد بالمعلومات الشخصية هي المعلومات التي
                ترتبط بشخص معين أو مؤسسة والتي تستخدم في التعريف عنهم.
              </p>
              </Fade>
            </div>
          </div>
   
        </div>
    
      </section>
    </>
  );
};

export default Someinfo;
