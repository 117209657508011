import React from "react";
import "./App.css";
import Navbarr from "./components/navbar/navbar";
import Intro from "./components/intro/intro";
import Someinfo from "./components/someinfo/someinfo";
import Infoappr from "./components/infoapp/right/infoapp";
import Infoappl from "./components/infoapp/left/infoapp";
import Ourfeature from "./components/ourfeature/ourfeature";
import About from "./components/about/about";
import lab from "../src/components/images/software-img.png";
import naqdia from "../src/components/images/nn.jpg";
import ajinda from "../src/components/images/ajj.jpg";

import "bootstrap/dist/css/bootstrap.min.css";
import Contact from "./components/contact/contact";
import Footer from "./components/footer/footer";
import { Container } from "react-bootstrap";

function App() {
  return (
    <div className="App">
      <Navbarr />
      <Intro />

      <h2 className="title text-center">التطبيقات</h2>
      <Infoappr
        custom2={"اعرف كل جنيه بيتصرف فين "}
        // img={naqdia}
        img={naqdia}
        namebtn="سجل الأن"
        custom1='
       
       لو زهقت من كتابة إيراداتك ومصروفاتك في أجندة أو شيت إكسل بلا جدوى، الحل الأفضل إشترك معانا في "تطبيق أعمال النقدية"'
        custom3={"حساباتك في أقصى درجات الأمان والسرية"}
        title="تطبيق أعمال النقدية"
        desc='
       أفضل وأسهل تطبيق سحابي لمتابعة أموالك وحساباتك الشخصية. ولا يتطلب أية خبرة في مجال المحاسبة؛ 

1- "تطبيق أعمال النقدية" يعد وسيلة بسيطة وسهلة الاستخدام لمساعدة الأفراد وأصحاب الأعمال الصغيرة والمدراء... لتسجيل نشاطهم المالي اليومي والتدفقات النقدية.
2- "تطبيق أعمال النقدية" فريد وسهل الاستعمال جدًّا.
3- "تطبيق أعمال النقدية" يمكن للعميل إدخال عدد من المستخدمين للاستفادة القصوى من مميزات التطبيق.
 4- أفضل طرق بحث بالتدفق النقدي لكل من (الإيرادات, والمصروفات)، ويُمكنك من الحصول على تقارير تفصيلية أو إجمالية حسب اختياركم.
'
        link="https://naqdia.a3maalapps.com/"
      />
      <Container>
        <hr />
      </Container>
      <Infoappr
        img={ajinda}
        namebtn="سجل الأن"
        title="تطبيق أعمال أجندة المحامي القضائية"
        custom2="اقوى تطبيق تطبيق إلكتروني متخصص لتنظيم أجندة المحامي  القضائية اليومية بداية من بيانات الموكلين والتوكيلات و الدعاوي وأجندة المحامي القضائية والعمل الإداري"
        custom1=""
        custom3="بياناتك في أقصى درجات الأمان والسرية"
        desc={`أفضل وأسهل تطبيق سحابي متخصص لتنظيم أجندة المحامي القضائية اليومية بداية من بيانات الموكلين والتوكيلات والدعاوي و أجندة المحامي القضائية
        * مواصفات التطبيق:
        - تطبيق أجندة المحامي القضائية يتيح لكم إمكانية إدارة القوائم المتنوعة داخل البرنامج (قائمة الموكلين، قائمة الخصوم، قائمة التوكيلات، قائمة جهات التوثيق، قائمة المحاكم، قائمة الدوائر،...) ويمكنكم اضافة البيانات داخل كل قائمة بتفاصيلها لسهولة الرجوع إليها في إي وقت وسهولة ودقة تنظيم العمل داخل البرنامج.
        - تطبيق أجندة المحامي القضائية يتيح لكم تسجيل كافة بيانات الموكلين وكذلك كافة بيانات التوكيلات المختلفة وتنظيمها وترتيبها وعرضها بسهولة ودقة وحفظ صورة من التوكيل وكذلك بيانات الخصوم وصفاتهم.
        - يمكنكم تطبيق أعمال أجندة المحامي القضائية من إدخال بيانات الدعاوى بأنواعها المختلفة مثل (الموكل وصفته والخصم وصفته، تاريخ رفع الدعوى، ونوع الدعوي ورقمها المحكمة والدائرة، المستندات المقدمة في الدعوى، وملخص للدعوى) ، كما يتيح لكم البرنامج إمكانية عرض كل الدعاوي وسهولة البحث عن أي دعوى باسم الموكل أو صفته أو الخصم أو بنوع الدعوى أو رقمها أو المحكمة.`}
        link="https://Ajindamuhami.a3maalapps.com/"
      />
      <Container>
        <hr />
      </Container>
      <Infoappr
        namebtn="قريباً / SOON"
        title="تطبيق أعمال حضور وانصراف"
        custom2="نظام حضور وانصراف اونلاين ذكي وبسيط"
        custom1="توفير الجهد والوقت والتكلفة تخلص من تعقيدات الأجهزة وتكاليف الصيانة"
        custom3="والتركيب وضياع الوقت في إدارة حضور وانصراف الموظفين للشركات الصغيرة ومتناهية الصغر والمكاتب والمؤسسات ذات العمالة المحدودة."
        img={lab}
        desc="نظام حضور وانصراف اونلاين ذكي وبسيط 
      
توفير الجهد والوقت والتكلفة
تخلص من تعقيدات الأجهزة وتكاليف الصيانة والتركيب وضياع الوقت في إدارة حضور وانصراف الموظفين للشركات الصغيرة ومتناهية الصغر والمكاتب والمؤسسات ذات العمالة المحدودة.

يوفر تطبيق أعمال طريقة سهلة ومنضبطة للتعرف على هوية الموظفين.
إثبات موقع الموظف
يوفر تطبيق أعمال أحدث التقنيات لإثبات مواقع الموظفين بدقة عالية بواسطة تقنية تحديد الموقع الجغرافي GPS التي تناسب أماكن العمل المفتوحة والواسعة.
"
        link="https://HR.a3maalapps.com/"
      />
      <Container>
        <hr />
      </Container>
      <Infoappr
        img={lab}
        custom2="المشروعات - المقايسات - التشغيل - المستخلصات"
        custom1="تطبيق أعمال للمقاولات يمكنكم من متابعة المشروعات والمواقع المرتبطة بها بدأ من عمل بنود للمقايسة وعمل تحليل لكل بند من المقايسة "
        custom3=""
        namebtn="قريباً / SOON"
        title="تطبيق أعمال للمقاولات"
        desc="المشروعات - المقايسات - التشغيل - المستخلصات  
        تطبيق أعمال للمقاولات يمكنكم من متابعة المشروعات والمواقع المرتبطة بها بدأ من عمل بنود للمقايسة وعمل تحليل لكل بند من المقايسة وعمل العقود وتنظيمها بين الجهة المسندة وشركات المقاولات ومقاولي الباطن وحساب تشغيل كل بند وتكلفته ومقارنته بالسعر التحليلي وعمل المستخلصات للجهات المسندة وشركات المقاولات و لمقاولي الباطن كما يتم متابعة سير العمل يومياً من خلال متابعة المشتريات بكل موقع من المواقع المنفذ بها المشروع - يتيح لكم البرنامج امكانية عمل الأرشيف الإلكتروني وذلك عن طريق ارفاق صورة مستندات أو ملفات داخل المشروع وذلك للحفاظ عليها و لسهولة الرجوع اليها في أي وقت 
        "
        link="https://muqawalat.a3maalapps.com/"
      />
      <Container>
        <hr />
      </Container>
      <Infoappr
        img={lab}
        namebtn="قريباً / SOON"
        custom2="أقوى تطبيق إلكتروني متخصص لتنظيم أعمال مكتب المحامي اليومية"
        custom1=" بداية من بيانات الموكلين والتوكيلات وبيانات الدعاوي "
        custom3="إلى تنظيم جلسات المحاكم وجلسات الخبراء بالإضافة إلى تنظيم أعمال المحضرين المتنوعة وكذلك تنظيم مهام الأجندة اليومية للمحامي وأيضا قاعدة بيانات كاملة لأعمال الشركات."
        title="تطبيق أعمال مكتب المحاماة"
        desc="تطبيق أعمال المحامي
أقوى تطبيق إلكتروني متخصص لتنظيم أعمال مكتب المحامي اليومية بداية من بيانات الموكلين والتوكيلات وبيانات الدعاوي إلى تنظيم جلسات المحاكم وجلسات الخبراء بالإضافة إلى تنظيم أعمال المحضرين المتنوعة وكذلك تنظيم مهام الأجندة اليومية للمحامي وأيضا قاعدة بيانات كاملة لأعمال الشركات.
"
        link="https://maktabmuhami.a3maalapps.com/"
      />
      <Container>
        <hr />
      </Container>
      <Infoappr
        img={lab}
        custom2="تطبيق أعمال المطابع وشركات الدعاية والإعلان بعد سنوات من الخبرة ودراسة السوق المصري"
        namebtn="قريباً / SOON"
        title="تطبيق أعمال المطابع"
        desc="تطبيق أعمال المطابع وشركات الدعاية والإعلان
بعد سنوات من الخبرة ودراسة السوق المصري خصوصا والعربي عموما يهدف موقع أعمال لإتاحة تطبيق لأعمال المطابع الأوفست والديجيتال والبلوتر وشركات الدعاية والإعلان ومصانع وورش السي أن سي
يهدف تطبيق أعمال الطباعة إلى توفير طريقة سهلة ومتميزة يمكن المستخدم من خلالها متابعة مقايسات الطباعة لحساب التكلفة كل مطبوع من حساب تكلفة الورق وحساب تكلفة الزنكين والأفلام والتراجعات وأعمال التشطيب وكذلك حساب المطبوعات الديجيتال
"
        link="https://dieaya.a3maalapps.com/"
      />
      <Container>
        <hr />
      </Container>
      <Someinfo />
      <Ourfeature />
      {/* <About /> */}
      <Contact />
      <Footer />
    </div>
  );
}
export default App;
