import React from "react";
import "./ourfeature.css";
import img1 from "../images/ARABIC2.png";
import { Fade } from "react-awesome-reveal";
const Ourfeature = () => {
  return (
    <>
      {" "}
      <section id="about">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-uppercase">من نحن</h2>
            </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <Fade direction="right"triggerOnce="false">
                      <img
                        src={img1}
                        className="img-responsive outt"
                        alt="feature img"
                      />
                    </Fade>
                  </div>
                  <div className="col-md-6">
                    <Fade direction="left"triggerOnce="false">
                      <h2
                        className="text-uppercase"
                        style={{ margin: "0px", paddingBottom: "10px" }}
                      >
                        موقع أعمال سوفت وير
                      </h2>
                      <p>
                        “موقع أعمال سوفت وير” منصة لعرض وتشغيل خدمة إستخدام
                        برامج أعمال المعتمدة على تقنية SaaS مما يتيح تجربة مميزة
                        للعملاء في استخدام البرامج لإنجاز مهامهم اليومية سواء
                        للأفراد او أعمال المكاتب أو الشركات متناهية الصغر
                        والصغيرة" . مجموعة من البرمجيات مبنية على SaaS تكنولوجي
                        التي يستخدمها العملاء لتسهيل أعمالهم اليومية سنساعدك على
                        إدارة مهام أعمالك بأعلى كفاءة ، من أي مكان وفي أي وقت
                        وبأقل التكاليف.
                      </p>
                      {/* <a
                        className="btn btn-primary text-uppercase"
                        href="https://cash-flow-z347.vercel.app/"
                        target="blank"
                      >
                        سجل الان
                      </a> */}
                    </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ourfeature;
