import React from "react";
import "./footer.css";
const Footer = () => {
  return (
    <>
      {" "}
      <footer>
        <div className="container">
          <div className="row">
            <p>
              {" "}
              جميع حقوق النشر والتوزيع محفوظة لموقع أعمال سوفت وير 
              &copy; 2024
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
